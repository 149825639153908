$(document).ready(function(){
	order_slider = $('#order_form .bxslider, #order_form_vip .bxslider').bxSlider({
		mode: 'fade',
		//auto: 7000,
		speed: 1000,
		controls: false,
		pager: false,
		adaptiveHeight: true,
        touchEnabled: false
	});

    home_slider = $('.home-slider .bxslider').bxSlider({
        //mode: 'fade',
        auto: 7000,
        speed: 1000,
        controls: false,
        //pager: false,
    });

    testimonial_slider = $('.testimonials .bxslider').bxSlider({
        mode: 'fade',
        auto: 10000,
        speed: 1000,
        controls: false,
        pager: false,
    });

	/*$('.order_next_button').click(function(){
		order_slider.goToSlide($(this).attr('data-go-to'));
		jQuery('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
	});*/

	$('.mobilemenu-menu').slicknav({
	    label: ''
	});

	$( ".datepicker" ).datepicker({dateFormat: 'yy-mm-dd', minDate: 0});

	$('.timepicker').timepicker({
		'timeFormat': 'H:i',
		'step': 15
	});

	$('.payment_panel').click(function(){
        $('.payment_panel').removeClass('payment_panel_active');
		$(this).addClass('payment_panel_active');
		$('input[name=payment_type]').val($(this).attr('data-payment'));
	});

	/*$('#order_form select[name=bp_pickup]').on('change', function(e){
		$('#order_form').parsley().destroy();
        var optionSelected = $("option:selected", this);
        var pickup_option = this.value * 1;
        if (pickup_option == 2) {
        	$('.bp_address').removeClass('hidden');
        	$('.bp_address .form_text').each(function(){
        		$(this).attr('data-parsley-required', 'true');
        	});
        	$('.bp_address .form_text').each(function(){
    			$(this).attr('data-parsley-group', 'block1');
        	});
        } else {
        	$('.bp_address').addClass('hidden');
        	$('.bp_address .form_text').each(function(){
        		$(this).attr('data-parsley-required', 'false');
        	});
        	$('.bp_address .form_text').each(function(){
    			$(this).attr('data-parsley-group', '');
        	});
        	$('.bp_address .form_text').val('');
        }
        var height = $(this).closest('li').css('height'); 
    	$('.bx-viewport').css("height",height);
        $('#order_form').parsley();
    });*/

	$('#order_form select[name=pickup]').on('change', function(e){
		$('#order_form').parsley().destroy();
        var optionSelected = $("option:selected", this);
        var pickup_option = this.value * 1;
        if (pickup_option == 2) {
        	$('.address').removeClass('hidden');
        	$('.address .form_text, .address select').each(function(){
        		$(this).attr('data-parsley-required', 'true');
        	});
        	$('.address .form_text, .address select').each(function(){
    			$(this).attr('data-parsley-group', 'block1');
        	});
        } else {
        	$('.address').addClass('hidden');
        	$('.address .form_text, .address select').each(function(){
        		$(this).attr('data-parsley-required', 'false');
        	});
        	$('.address .form_text, .address select').each(function(){
    			$(this).attr('data-parsley-group', '');
        	});
        	$('.address .form_text, .address select').val('');
        }
        var height = $(this).closest('li').css('height'); 
    	$('.bx-viewport').css("height",height);
        $('#order_form').parsley();
    });

    $('#order_form select[name=pickup_retur]').on('change', function(e){
        $('#order_form').parsley().destroy();
        var optionSelected = $("option:selected", this);
        var pickup_option = this.value * 1;
        if (pickup_option == 2) {
            $('.bp_address').removeClass('hidden');
            $('.bp_address .form_text, .bp_address select').each(function(){
                $(this).attr('data-parsley-required', 'true');
            });
            $('.bp_address .form_text, .bp_address select').each(function(){
                $(this).attr('data-parsley-group', 'block1');
            });
        } else {
            $('.bp_address').addClass('hidden');
            $('.bp_address .form_text, .bp_address select').each(function(){
                $(this).attr('data-parsley-required', 'false');
            });
            $('.bp_address .form_text, .bp_address select').each(function(){
                $(this).attr('data-parsley-group', '');
            });
            $('.bp_address .form_text, .bp_address select').val('');
        }
        var height = $(this).closest('li').css('height'); 
        $('.bx-viewport').css("height",height);
        $('#order_form').parsley();
    });

    $('#order_form select[name=destination], #order_form_vip select[name=destination]').on('change', function(e){
        var optionSelected = $("option:selected", this);
        var travel_option = this.value * 1;
        //alert(travel_option);
        selected_text = $(this).find("option:selected").text();
        $('.block1 .title_2').html(selected_text);
        destinations = selected_text.split(' - ');
        retur_text = destinations[1]+' - '+destinations[0];
        $('.from_bp_div .title_2').html(retur_text);

        if ((travel_option == 1) || (travel_option == 3) || (travel_option == 5) || (travel_option == 7)) {
            jQuery('label.arrive').addClass('hidden');
            jQuery('label.going').removeClass('hidden');
            if ($('input[name=language]').val()=='hu') {
                $('select[name=pickup] option:last-child').html('Lakcímen szeretnék beszállni');
                $('select[name=pickup_retur] option:last-child').html('Lakcímen szeretnék kiszállni');
            }
        } else {
            jQuery('label.arrive').removeClass('hidden');
            jQuery('label.going').addClass('hidden');
            if ($('input[name=language]').val()=='hu') {
                $('select[name=pickup] option:last-child').html('Lakcímen szeretnék kiszállni');
                $('select[name=pickup_retur] option:last-child').html('Lakcímen szeretnék beszállni');
            }
        }
    });

    $('input[name=retur]').on('click', function(e){
        if ($(this).is(":checked")) {
            $('.from_bp_div, .order_form_border_div').removeClass('hidden');
            $('.from_bp_div select, .from_bp_div .form_text').each(function(){
                if (($(this).attr('name') != 'city_retur') && ($(this).attr('name') != 'address_retur')) {
                    $(this).attr('data-parsley-required', 'true');
                }
            });
            $('.from_bp_div select, .from_bp_div .form_text').each(function(){
                if (($(this).attr('name') != 'city_retur') && ($(this).attr('name') != 'address_retur')) {
                    $(this).attr('data-parsley-group', 'block1');
                }
            });
        } else {
            $('.from_bp_div, .order_form_border_div').addClass('hidden');
            $('.from_bp_div select, .from_bp_div .form_text').each(function(){
                if (($(this).attr('name') != 'city_retur') && ($(this).attr('name') != 'address_retur')) {
                    $(this).removeAttr('data-parsley-required');
                }
            });
            $('.from_bp_div select, .from_bp_div .form_text').each(function(){
                if (($(this).attr('name') != 'city_retur') && ($(this).attr('name') != 'address_retur')) {
                    $(this).removeAttr('data-parsley-group');
                }
            });
        }
        $('#order_form').parsley();
    });

	/*$('#order_form select[name=destination]').on('change', function(e){
		$('#order_form').parsley().destroy();
        var optionSelected = $("option:selected", this);
        var travel_option = this.value * 1;
        if (travel_option == 1) {
        	//$('.from_div').removeClass('hidden');
        	//$('.from_bp_div, .order_form_border_div').addClass('hidden');
        	$('.from_div select, .from_div .form_text').each(function(){
        		if (($(this).attr('name') != 'city') && ($(this).attr('name') != 'address')) {
        			$(this).attr('data-parsley-required', 'true');
        		}
        	});
        	$('.from_div select, .from_div .form_text').each(function(){
        		if (($(this).attr('name') != 'city') && ($(this).attr('name') != 'address')) {
        			$(this).attr('data-parsley-group', 'block1');
        		}
        	});
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
    			$(this).attr('data-parsley-required', 'false');
        	});
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
    			$(this).attr('data-parsley-group', '');
        	});
        } else if (travel_option == 2) {
        	//$('.from_bp_div').removeClass('hidden');
        	//$('.from_div, .order_form_border_div').addClass('hidden');
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
        		if (($(this).attr('name') != 'bp_city') && ($(this).attr('name') != 'bp_address')) {
        			$(this).attr('data-parsley-required', 'true');
        		}
        	});
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
        		if (($(this).attr('name') != 'bp_city') && ($(this).attr('name') != 'bp_address')) {
        			$(this).attr('data-parsley-group', 'block1');
        		}
        	});
        	$('.from_div select, .from_div .form_text').each(function(){
    			$(this).attr('data-parsley-required', 'false');
        	});
        	$('.from_div select, .from_div .form_text').each(function(){
    			$(this).attr('data-parsley-group', '');
        	});
        } else if (travel_option == 3) {
        	//$('.from_bp_div, .from_div, .order_form_border_div').removeClass('hidden');
        	$('.from_div select, .from_div .form_text').each(function(){
        		if (($(this).attr('name') != 'city') && ($(this).attr('name') != 'address')) {
        			$(this).attr('data-parsley-required', 'true');
        		}
        	});
        	$('.from_div select, .from_div .form_text').each(function(){
        		if (($(this).attr('name') != 'city') && ($(this).attr('name') != 'address')) {
        			$(this).attr('data-parsley-group', 'block1');
        		}
        	});
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
        		if (($(this).attr('name') != 'bp_city') && ($(this).attr('name') != 'bp_address')) {
        			$(this).attr('data-parsley-required', 'true');
        		}
        	});
        	$('.from_bp_div select, .from_bp_div .form_text').each(function(){
        		if (($(this).attr('name') != 'bp_city') && ($(this).attr('name') != 'bp_address')) {
        			$(this).attr('data-parsley-group', 'block1');
        		}
        	});
        }
        $('#order_form').parsley();
    });*/

    $('.extra_costs table').addClass('table');
    $('.extra_costs table').addClass('table-striped');
    $('.extra_costs table').attr('border', '0');

    $('input[name=home_transfer]').click(function(){
        if ($(this).is(":checked")) {
            $('.extra_costs').removeClass('hidden');
        } else {
            $('.extra_costs').addClass('hidden');
        }
    });

	$('#order_form').on('click', '.order_next_button', function () {
        if (!$(this).hasClass('form_button')) {                
            var current = $(this).attr('data-current'),
            next = $(this).attr('data-go-to');

            if (current == 0 ) {
                if (next > current) {
                    if (false === $('#order_form').parsley().validate('block' + current)) {
                        //alert('no');
                        return;
                    } else {
                        if (!$(this).hasClass('form_button')) {
                            slide_id = $(this).attr('data-slide');
                            order_slider.goToNextSlide();
                            $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                        }
                    }

                }
            } else if (current == 1) {
                if (false === $('#order_form').parsley().validate('block' + current)) {
                    return;
                } else {
                    $.ajax({
                        type: "POST",
                        url: "/calculate-transfer-price",
                        headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
                        data: $('#order_form').serialize()
                    }).done(function(data) {
                        if (data.success) {
                            $('.price_span').html(data.price);
                        }
                    });
                    order_slider.goToNextSlide(slide_id);
                    $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                }
            } else {
                if (false === $('#order_form').parsley().validate('block' + current)) {
                    return;
                } else {
                    order_slider.goToNextSlide(slide_id);
                    $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                }
            }
        } else {
            if (false === $('#order_form').parsley().validate()) {
                return;
            } else {
                $('#order_form').submit();
            }
        }
    });

    $('#order_form_vip').on('click', '.order_next_button', function () {
        if (!$(this).hasClass('form_button')) {                
            var current = $(this).attr('data-current'),
            next = $(this).attr('data-go-to');

            if (current == 0 ) {
                if (next > current) {
                    if (false === $('#order_form_vip').parsley().validate('block' + current)) {
                        //alert('no');
                        return;
                    } else {
                        if (!$(this).hasClass('form_button')) {
                            slide_id = $(this).attr('data-slide');
                            order_slider.goToNextSlide();
                            $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                        }
                    }

                }
            } else if (current == 1) {
                if (false === $('#order_form_vip').parsley().validate('block' + current)) {
                    return;
                } else {
                    $.ajax({
                        type: "POST",
                        url: "/calculate-transfer-price-vip",
                        headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
                        data: $('#order_form_vip').serialize()
                    }).done(function(data) {
                        if (data.success) {
                            $('.price_span').html(data.price);
                        }
                    });
                    order_slider.goToNextSlide(slide_id);
                    $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                }
            } else {
                if (false === $('#order_form_vip').parsley().validate('block' + current)) {
                    return;
                } else {
                    order_slider.goToNextSlide(slide_id);
                    $('html, body').animate({scrollTop:$('.title_1').offset().top}, 'slow');
                }
            }
        } else {
            if (false === $('#order_form_vip').parsley().validate()) {
                return;
            } else {
                $('#order_form_vip').submit();
            }
        }
    });

    $('body').on('submit', '#calculator_form', function(e){
        e.preventDefault();
        $('.calculator_result').addClass('opacity_0');
        $('.ajax_loader').removeClass('opacity_0');
        $.ajax({
            type: "POST",
            url: "/calculate-price",
            headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
            data: $('#calculator_form').serialize()
        }).done(function(data) {
            if (data.success) {
                $('.calculator_result_span').html(data.price);
            } else {
                //$('.calculator_result').html('There was an error, please try again later.');
            }
            $('.calculator_result').removeClass('opacity_0');
            $('.ajax_loader').addClass('opacity_0');
        });
    });

    /*$('body').on('submit', '#calculator_form', function(e){
        e.preventDefault();
        $('.calculator_result').addClass('opacity_0');
        $('.ajax_loader').removeClass('opacity_0');
        $.ajax({
            type: "POST",
            url: "/calculate-transfer-price",
            headers: { 'X-CSRF-TOKEN': $('input[name="_token"]').val() },
            data: {
                destination : $('select[name=destination]').val(),
                pickup : $('select[name=pickup]').val(),
                retur : $('input[name=retur]').val(),
            }
        }).done(function(data) {
            if (data.success) {
                $('.price_span').html(data.price);
            } else {
                //$('.calculator_result').html('There was an error, please try again later.');
            }
            $('.calculator_result').removeClass('opacity_0');
            $('.ajax_loader').addClass('opacity_0');
        });
    });*/

    $('.slide > .row').each(function(){
        $(this).css('padding-top', (( $(this).parent().height() - $(this).height()) / 2)+'px');
    });

     equalHeight($('.column'));
});

function equalHeight(group) {
   tallest = 0;
   group.each(function() {
           thisHeight = jQuery(this).height();
           if(thisHeight > tallest) {
                   tallest = thisHeight;
           }
   });
   group.height(tallest);
}